.x-ui-admin {
  .logo {
    height: 32px;
    margin: 16px;
    text-align: center;
    img {
      height: 32px;
    }
  }

  .x-ui-admin-header {
    background: #fff;
    padding: 0;
    text-align: right;
    padding-right: 16px;
  }

  .x-ui-admin-footer {
    font-family: monospace;
    color: #555;
    text-transform: uppercase;
  }
}

.ant-descriptions-item-label {
  font-weight: 500;
}
